import { IDownloadAppComponentProps } from '../../../lib/types/components';
import { dynamicCssSize, getAppleAppUrl, getCountryFromLocale, isMobile } from '../../../lib/utils/constants/PagesConstants';
import CustomImageComponent from '../CustomImage/CustomImageComponent';

const DownloadAppComponentV2 = (props: IDownloadAppComponentProps) => {

    const {downloadAppData, androidUrl} = props;

    return (
        downloadAppData ?
            <>
                <style jsx>{`
                  .downloadContainer {
                    position: relative;
                    font-family: var(--font-primary);
                    padding: 18px 0px 4.6vw;
                    color: white;
                  }

                  .styledRow {
                    width: auto;
                    flex-direction: row;
                    align-items: center;
                  }

                  .mobileImgContainer {
                    height: ${dynamicCssSize(800, 250)};
                  }

                  .styledCol {
                    padding: 0px 50px 0px 25px;
                    height: 100%;
                  }

                  .headingDesk {
                    font-size: ${dynamicCssSize(40, 25)};
                    font-weight: 900;
                    line-height: 1.4;
                  }

                  .listContainer {
                    margin-top: 15px;
                    margin-bottom: 15px;
                  }

                  .listContainer ul {
                    padding: 15px;
                  }

                  .listContainer ul li {
                    list-style-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/listBullet.webp");
                    margin-bottom: 10px;
                    font-size: ${dynamicCssSize(24, 12)};
                    letter-spacing: -0.32px;
                  }

                  .downloadImgContainer {
                    flex-wrap: nowrap !important;
                    cursor: pointer;
                    display:flex;
                    padding-left:15px;
                    padding-right:15px;
                  }

                  .downloadImg {
                    text-align: center;
                    width: 100%;
                    height: 50px;
                    object-fit: contain;
                    padding-left: 30px;
                    padding-right: 0;
                    position: relative;
                  }
                  .imgContainer{
                    flex:1;
                    margin-left:5px;
                    margin-right:5px;
                  }
                  
                  .downloadImg:nth-child(2){
                    padding-left: 0px;
                  }

                  .headingMobContainer {
                    display: none;
                  }

                  @media screen and (max-width: 767px) {
                    .container {
                      height: 100%;
                    }

                    .headingMobContainer {
                      position: relative;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      z-index: 1;
                    }

                    .styledRow {
                      height: 100%;
                      flex-direction: column;
                    }

                    .headingMob {
                      text-align: center;
                      font-size: 16px;
                      font-weight: 900;
                      font-style: italic;
                      line-height: 1.5;
                      max-width: 150px;
                    }

                    .mobileImgContainer {
                      min-height: 262px;
                    }

                    .headingDesk {
                      display: none;
                    }

                    .styledCol {
                      padding: 0 17px;
                    }

                    .listContainer {
                      margin: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .listContainer ul {
                      margin: 0;
                      padding-inline-start: 20px;
                      text-align: center;
                      padding: 10px 15px;
                    }

                    .listContainer ul li {
                      text-align: left;
                      letter-spacing: -0.32px;
                      padding-bottom: 10px;
                    }

                    .downloadImgContainer > div {
                      margin-bottom: 20px;
                    }
                    .downloadImg{
                      padding: 0px 15px;
                    }
                  }
                `}
                </style>
                <div className="downloadContainer container-fluid">
                    <div className='headingMobContainer'>
                        <h2 className="headingMob">
                            {downloadAppData.heading}
                        </h2>
                    </div>
                    <div>
                        <CustomImageComponent
                            src={isMobile ?
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/downloadAppMobBack.webp"
                                : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/donwloadAppBackground.webp"
                            }
                            layout={"fill"}
                            objectFit={"cover"}
                            alt={"sponsor-background"}
                        />
                    </div>
                    <div className={"container"}>
                        <div className="styledRow row">
                            <div className="mobileImgContainer col">
                                <CustomImageComponent
                                    src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/mobileImg.webp"}
                                    layout={"fill"}
                                    objectFit={"contain"}
                                    alt="mobile_winzo_image"
                                />
                            </div>
                            <div className="styledCol col">
                                <h2 className="headingDesk">
                                    {downloadAppData.heading}
                                </h2>
                                <div className="listContainer">
                                    <ul>
                                        {

                                            downloadAppData.list.map((item, index) => {
                                                return (
                                                    <li key={"downloadAppData_" + index}>
                                                        {item}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="downloadImgContainer row">
                                    {
                                        downloadAppData.downloadImg.map((item, index) => {
                                            return (
                                                <div key={"downloadImg_" + index} className ="imgContainer">
                                                    <div className="downloadImg col" id={index === 0?"android_download_button":"ios_download_button"}>
                                                        <a
                                                            href={index === 0 ? androidUrl : getAppleAppUrl(getCountryFromLocale(props.locale))}
                                                            suppressHydrationWarning
                                                        >
                                                            <CustomImageComponent
                                                                layout={"fill"}
                                                                objectFit="contain"
                                                                objectPosition={"center"}
                                                                src={item.url}
                                                                alt={"download-image"}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <></>
    )
}

export default DownloadAppComponentV2;